
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class TagService {
	async getOptions(searchString) {
		const queryParams = {
			search: searchString
		}

		return await apiService.get('tags/options', {}, queryParams)
	}
}
