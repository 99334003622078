
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button";

class AdminEventConfirmDateChange extends React.Component {
	state = {
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Event Date Has Changed</h2>
				<p>By clicking 'Yes' the new event date will be saved and notification emails will be sent to all ballot users.
					<br/><br/><b>Attention: Proceed with the date change?</b></p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Cancel</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Yes</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleConfirm = () => {
		const {handleConfirm} = this.props
		handleConfirm()
	}
}

export default AdminEventConfirmDateChange
