
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class FeaturedEventService {
	async get() {
		return await apiService.get('featured-events')
	}

	async getOptions(search) {
		const queryParams = {
			search: search,
			sort: "starts",
			limit: 50,
			filter: {
				status_id: 1
			},		}
		return await apiService.get('featured-events/options', {}, queryParams)
	}

	formData(inputValues) {
		const events = []
		inputValues.hero_current.forEach(value => {
			events.push(value)
		})
		inputValues.hero_queue.forEach(value => {
			events.push(value)
		})
		inputValues.featured_current.forEach(value => {
			events.push(value)
		})
		inputValues.featured_queue.forEach(value => {
			events.push(value)
		})

		return JSON.stringify({"events": events})
	}

	async update(formData = {}) {
		return await apiService.post(`featured-events/update`, {body: formData})
	}
}
