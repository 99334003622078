
// Libraries
import * as React from 'react'
import moment from 'moment'

// Services
import FeaturedEventService from '../../../services/featuredEvent'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import Modal from '../../modal'
import AdminEventViewRow from './adminEventViewRow'
import Button from '../../button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faThumbtack} from '@fortawesome/pro-solid-svg-icons'
import AdminFeaturedEventsInputs from './adminFeaturedEventsInputs'

class AdminFeaturedEventsEdit extends React.Component {
	state = {
		inputValues: {
			hero_current: [],
			hero_queue: [],
			featured_current: [],
			featured_queue: []
		},
		inputErrors: {},
		isSubmitting: false,
	}

	static contextType = NotificationContext

	render() {
		const {handleClose} = this.props
		const {inputValues, inputErrors, isSubmitting} = this.state

		return <Modal handleClose={handleClose} modalRight adminForm>
			<div className="admin-form admin-form--event">
				<div className="admin-form__header">
					<h3><FontAwesomeIcon icon={faThumbtack} title="Thumb tack icon" /> Featured Events</h3>
				</div>
				<div className="admin-form__form">
					<form onSubmit={this.handleSubmit} encType="multipart/form-data">
						<div className="admin-form__field-group">
							<h4>Hero Events</h4>
							<p>Select the main hero event shown at the top of
								the <a href="/events" target="_blank">Events Page</a>.<br/>
								The <i>Current</i> event will be set as the hero event right now, while
								the <i>Queue</i> allows you to specify other events that will take its place when
								the current event closes or gets cancelled.</p>
							<AdminEventViewRow
								label="Current"
								value={<AdminFeaturedEventsInputs
									name="hero_current"
									type="hero"
									values={inputValues?.hero_current}
									error={inputErrors?.hero_current}
									inputChange={this.handleInputChange}
									hideButtons={true}
								/>} />
							<AdminEventViewRow
								label="Queue"
								value={<AdminFeaturedEventsInputs
									name="hero_queue"
									type="hero"
									values={inputValues?.hero_queue}
									error={inputErrors?.hero_queue}
									inputChange={this.handleInputChange}
									hideButtons={false}
									inputAdd={this.handleEventAdd}
									inputRemove={this.handleEventRemove}
								/>} />
						</div>
						<div className="admin-form__field-group">
							<h4>Featured Events</h4>
							<p>Select the 3 featured events shown in the <a href="/" target="_blank">
								Home Page</a> and <a href="/events" target="_blank">Events Page</a>.<br/>
								The <i>Current</i> events will be set as the featured events right now, while
								the <i>Queue</i> allows you to specify other events that will take their place when
								the current events close or get cancelled.</p>
							<AdminEventViewRow
								label="Current"
								value={<AdminFeaturedEventsInputs
									name="featured_current"
									type="featured"
									values={inputValues?.featured_current}
									error={inputErrors?.featured_current}
									inputChange={this.handleInputChange}
									hideButtons={true}
								/>} />
							<AdminEventViewRow
								label="Queue"
								value={<AdminFeaturedEventsInputs
									name="featured_queue"
									type="featured"
									values={inputValues?.featured_queue}
									error={inputErrors?.featured_queue}
									inputChange={this.handleInputChange}
									hideButtons={false}
									inputAdd={this.handleEventAdd}
									inputRemove={this.handleEventRemove}
								/>} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={handleClose} colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={isSubmitting} colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	async componentDidMount() {
		const {featuredEvents} = this.props
		const {inputValues} = this.state

		featuredEvents?.hero?.forEach((featuredEvent, key) => {
			if (key === 0) {
				inputValues.hero_current.push({
					type: featuredEvent.type,
					value: featuredEvent.event_id,
					label: `${featuredEvent.event.name} 
						(${featuredEvent.event.venue.name} - 
						${moment(featuredEvent.event.starts).format('DD MMM YYYY')})`
				})
			}
			else {
				inputValues.hero_queue.push({
					type: featuredEvent.type,
					value: featuredEvent.event_id,
					label: `${featuredEvent.event.name} 
						(${featuredEvent.event.venue.name} - 
						${moment(featuredEvent.event.starts).format('DD MMM YYYY')})`
				})
			}
		})
		featuredEvents?.featured?.forEach((featuredEvent, key) =>  {
			if (key < 3) {
				inputValues.featured_current.push({
					type: featuredEvent.type,
					value: featuredEvent.event_id,
					label: `${featuredEvent.event.name} 
						(${featuredEvent.event.venue.name} - 
						${moment(featuredEvent.event.starts).format('DD MMM YYYY')})`
				})
			}
			else {
				inputValues.featured_queue.push({
					type: featuredEvent.type,
					value: featuredEvent.event_id,
					label: `${featuredEvent.event.name} 
						(${featuredEvent.event.venue.name} - 
						${moment(featuredEvent.event.starts).format('DD MMM YYYY')})`
				})
			}
		})

		// add blank recipient values
		if ( inputValues.hero_current.length === 0 ) {
			inputValues.hero_current.push({
				type: 'hero',
				value: ''
			})
		}
		if ( inputValues.hero_queue.length < 3 ) {
			const forStop = 3 - inputValues.hero_queue.length
			for (let i = 0; i < forStop; i++) {
				inputValues.hero_queue.push({
					type: 'hero',
					value: ''
				})
			}
		}
		if ( inputValues.featured_current.length < 3 ) {
			const forStop = 3 - inputValues.featured_current.length
			for (let i = 0; i < forStop; i++) {
				inputValues.featured_current.push({
					type: 'featured',
					value: ''
				})
			}
		}
		if ( inputValues.featured_queue.length < 3 ) {
			const forStop = 3 - inputValues.featured_queue.length
			for (let i = 0; i < forStop; i++) {
				inputValues.featured_queue.push({
					type: 'featured',
					value: ''
				})
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleEventAdd = (name, type) => {
		// Get current form data from state
		const {inputValues} = this.state

		// add blank recipient value
		inputValues[name].push({
			value: '',
			type: type
		})

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleEventRemove = (name, key) => {
		// Get current form data from state
		const {inputValues} = this.state

		// remove recipient value from state
		inputValues[name].splice(key, 1)

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			const prevValues = inputValues[scope][name];
			inputValues[scope][name] = {...value, ...{
				type: prevValues.type
			}}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent = null) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {handleComplete} = this.props
		const {inputValues} = this.state
		const featuredEventService = new FeaturedEventService()
		const {addNotification} = this.context

		// submit event data
		const formData = featuredEventService.formData(inputValues)
		await featuredEventService.update(formData)
			.then(async (apiResponse) => {
				if (apiResponse.success) {
					addNotification('Featured Events Updated', 'success')
					handleComplete(apiResponse.data)
				}
				else {
					this.setState({
						inputErrors: apiResponse.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminFeaturedEventsEdit
