
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import ApiService from '../../../services/api'
import FeaturedEventService from '../../../services/featuredEvent'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminEventsTableHeader from '../../../components/admin/events/adminEventsTableHeader'
import AdminEventsTable from '../../../components/admin/events/adminEventsTable'
import AdminEventView from '../../../components/admin/events/adminEventView'
import AdminEventEdit from '../../../components/admin/events/adminEventEdit'
import AdminFeaturedEventsEdit from '../../../components/admin/events/adminFeaturedEventsEdit'
import AdminEventConfirmCancel from '../../../components/admin/events/adminEventConfirmCancel'
import AdminEventConfirmUncancel from '../../../components/admin/events/adminEventConfirmUncancel'
import AdminEventConfirmDelete from '../../../components/admin/events/adminEventConfirmDelete'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarStar, faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons'
import Button from '../../../components/button'
import Pagination from '../../../components/pagination'
import ReactTooltip from 'react-tooltip'

class AdminEventsIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {}
		},
		events: [],
		pagination: [],
		emptyMessage: 'Retrieving event data ...',
		selectedEvent: {},
		featuredEvents: {
			hero: [],
			featured: []
		},
		modalViewShow: false,
		modalEditShow: false,
		modalFeaturedShow: false,
		modalConfirmCancelShow: false,
		modalConfirmUncancelShow: false,
		modalConfirmDeleteShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, events, featuredEvents, pagination, emptyMessage, selectedEvent, modalViewShow,
			modalEditShow, modalFeaturedShow, modalConfirmCancelShow, modalConfirmUncancelShow,
			modalConfirmDeleteShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-events nav-blue-half">
				<Seo title="Events Management" />
				<div className="admin-table__wrap admin-table__wrap--events">
					<AdminEventsTableHeader
						titleText="Events Management" titleIcon={faCalendarStar}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<p className='filters-status'>
						Filter by:
						<a href="/admin/events/?filter[status_id]=1" className={activeFilters?.filter?.status_id === '1' && 'active'}>Open</a>
						<a href="/admin/events/?filter[status_id]=3" className={activeFilters?.filter?.status_id === '3' && 'active'}>To Assign</a>
						<a href="/admin/events/?filter[status_id]=4,5" className={(activeFilters?.filter?.status_id === '4,5') && 'active'}>Archived</a>
					</p>

					<div className="buttons-wrap">
						<Button to="/admin/events/new" colorEndeavour>New Event</Button>
						<Button onClick={() => this.setState({modalFeaturedShow: true})}>
							Manage Featured Events
						</Button>
						{(featuredEvents?.hero?.length === undefined || featuredEvents?.hero?.length < 4 ||
							featuredEvents?.featured?.length === undefined || featuredEvents?.featured?.length < 6) &&
							<span className="warning-icon" data-tip={true} data-for="tooltip-featured">
								<FontAwesomeIcon icon={faExclamationTriangle} />
								<ReactTooltip id="tooltip-featured" place="right" backgroundColor="#005EB8" textColor="#fff">
									<span>Less than 4 Hero or 6 Featured events currently selected. Please select more.</span>
								</ReactTooltip>
							</span>
						}
					</div>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminEventsTable
						events={events}
						getEvents={this.getEvents}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminEventView
						event={selectedEvent}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleConfirmCancel={this.handleConfirmCancel}
						handleConfirmUncancel={this.handleConfirmUncancel}
						handleConfirmDelete={this.handleConfirmDelete} />}

					{modalEditShow && <AdminEventEdit
						event={selectedEvent}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalFeaturedShow && <AdminFeaturedEventsEdit
						featuredEvents={featuredEvents}
						handleClose={() => this.setState({
							modalFeaturedShow: false
						})}
						handleComplete={this.handleFeaturedComplete} />}

					{modalConfirmCancelShow && <AdminEventConfirmCancel
						event={selectedEvent}
						handleClose={() => this.setState({modalConfirmCancelShow: false})}
						handleConfirm={this.handleProcessCancel} />}

					{modalConfirmUncancelShow && <AdminEventConfirmUncancel
						event={selectedEvent}
						handleClose={() => this.setState({modalConfirmUncancelShow: false})}
						handleConfirm={this.handleProcessUncancel} />}

					{modalConfirmDeleteShow && <AdminEventConfirmDelete
						event={selectedEvent}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={this.handleProcessDelete} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		this.fetchFeaturedEvents()

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
	
		Promise.all([
			this.getEvents({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the events.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters: {...activeFilters, ...searchParams},
				emptyMessage: "No events found, try adjusting your filters"
			})
		})
	}

	getEvents = (queryParams = {}) => {
		const apiService = new ApiService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1

		if (!queryParams['filter']['status_id']) {
			queryParams['filter']['status_id'] = "1"
		}
		return apiService.get('events', {}, queryParams).then( (response) => {
			this.setState({
				events: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	async fetchFeaturedEvents() {
		const featuredEventService = new FeaturedEventService()
		const featuredEventResponse = await featuredEventService.get()

		this.setState({
			featuredEvents: featuredEventResponse.data
		})
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getEvents(activeFilters)
				.catch(() => addNotification('There was an error filtering the events.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getEvents(activeFilters)
				.catch(() => addNotification('There was an error filtering the events.', 'error'))
		})
	}

	handleOpenView = (event_id) => {
		const apiService = new ApiService()
		return apiService.get(`events/${event_id}`)
			.then( (response) => {
				this.updateStateSelectedEvent(response.data)
				this.setState({
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (event_id) => {
		const apiService = new ApiService()
		return apiService.get(`events/${event_id}`)
			.then( (response) => {
				this.updateStateSelectedEvent(response.data)
				this.setState({
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleEditComplete = (event) => {
		this.updateStateSelectedEvent(event)
		this.updateStateEvents(event)
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
	}

	handleFeaturedComplete = (events) => {
		this.setState({
			modalFeaturedShow: false,
			featuredEvents: events
		})
	}

	handleConfirmCancel = () => {
		this.setState({
			modalConfirmCancelShow: true
		})
	}

	handleConfirmUncancel = () => {
		this.setState({
			modalConfirmUncancelShow: true
		})
	}

	handleConfirmDelete = () => {
		this.setState({
			modalConfirmDeleteShow: true
		})
	}

	handleProcessCancel = (event_id, reason) => {
		const {addNotification} = this.context

		const apiService = new ApiService()
		const responseOptions = {
			body: JSON.stringify({
				cancel_reason: reason,
			}),
		}

		return apiService.post(`events/${event_id}/cancel`, responseOptions)
			.then( (response) => {
				if (response.data?.success) {
					addNotification(response.data?.message, 'success')
					this.setState({
						modalViewShow: false,
						modalConfirmCancelShow: false
					})
				} else {
					addNotification('Unable to cancel event', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleProcessUncancel = () => {
		const {selectedEvent} = this.state
		const {addNotification} = this.context

		const apiService = new ApiService()
		return apiService.post(`events/${selectedEvent.id}/uncancel`)
			.then( (response) => {
				if (response.data?.success) {
					addNotification(response.data?.message, 'success')
					this.setState({
						modalViewShow: false,
						modalConfirmUncancelShow: false
					})
				} else {
					addNotification('Unable to uncancel event', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	handleProcessDelete = () => {
		const {selectedEvent} = this.state
		const {addNotification} = this.context

		const apiService = new ApiService()
		return apiService.delete(`events/${selectedEvent.id}`)
			.then( (response) => {
				if (response.data?.deleted) {
					addNotification('Event deleted', 'success')

					this.updateStateEvents(selectedEvent, true)
					this.setState({
						modalViewShow: false,
						modalConfirmDeleteShow: false
					})
				} else {
					addNotification('Unable to delete event', 'error')
				}
			})
			.catch(err => console.error(err))
	}

	updateStateSelectedEvent = (event) => {
		this.setState(prevState => ({
			selectedEvent: {...prevState.selectedEvent, ...event}
		}))
	}

	updateStateEvents = (event, remove = false) => {
		const {events} = this.state

		// update events state
		events.forEach((existingEvent, key) => {
			if (existingEvent.id === event.id) {
				if (remove) {
					events.splice(key, 1)
				}
				else {
					events[key] = {...existingEvent, ...event}
				}
			}
		})

		this.setState({events})
	}
}

export default AdminEventsIndexPage
