
// Libraries
import React from 'react'
import Classnames from 'classnames'
import debounce from 'debounce-promise'

// Services
import FeaturedEventService from '../../../services/featuredEvent'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import Button from '../../button'
import InputFactory from '../../inputFactory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlus } from '@fortawesome/pro-regular-svg-icons'

export default class AdminFeaturedEventsInputs extends React.Component {
	constructor(props) {
		super(props);

		const wait = 1000; // milliseconds
		const loadOptions = (inputValue) => this.loadOptions(inputValue);
		this.debouncedLoadOptions = debounce(loadOptions, wait, {
			leading: true
		});
	}

	static contextType = NotificationContext

	render() {
		const {name, type, values, inputChange, inputAdd, inputRemove, hideButtons} = this.props

		let classes = Classnames([
			'input-wrap',
			'input-wrap--featured-events',
		])

		return <div className={classes}>
			<div className='selects-wrap'>
				{values?.length
					? values.map((value, key) => {
						return <div className='selects-wrap__select'>
							<InputFactory
								type="select"
								name={key}
								scope={name}
								value={value?.value ? value : ''}
								noOptionsMessage={() => "Start typing to search ..."}
								loadOptions={(inputValue) => this.debouncedLoadOptions(inputValue)}
								onChange={inputChange}
								defaultOptions
							/>
							{!hideButtons && <Button type='button' iconOnly danger onClick={() => inputRemove(name, key)}>
								<FontAwesomeIcon icon={faTimesCircle} /><span className='sr-only'> Remove</span>
							</Button>}
						</div>
					})
					: <p>Click a button below to start ...</p>
				}
			</div>
			{!hideButtons && <div className='buttons-wrap'>
				<Button type='button' onClick={() => inputAdd(name, type)}>
					<FontAwesomeIcon icon={faPlus}/> Add event
				</Button>
			</div>}
		</div>
	}

	loadOptions = (inputValue) => {
		const featuredEventsService = new FeaturedEventService()
		return featuredEventsService.getOptions(inputValue)
			.then((response) => {
				if (response.success) {
					return response.data
				}
				else {
					const {addNotification} = this.context
					addNotification('Search failed', 'error')
					return []
				}
			}).catch(() => {
				const {addNotification} = this.context
				addNotification('Search failed', 'error')
			})
	}
}
