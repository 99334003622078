
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button"

class AdminEventConfirmUncancel extends React.Component {
	state = {
		cancelMessage: '',
		formValue: '',
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Uncancel this event?</h2>
				<p>All users that applied for ballots previously will be sent an email inviting them to reapply for the new date.</p>
				<p><b>Warning: Please check that the event's date has been updated before proceeding.</b></p>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorEndeavour>Uncancel Event</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleInputChange = value => {
		this.setState({cancelMessage: value || ''})
	}

	handleConfirm = async () => {
		const {handleConfirm} = this.props

		this.setState({
			isConfirming: true
		})

		handleConfirm().then(() => {
			this.setState({
				isConfirming: false
			})
		})
	}
}

export default AdminEventConfirmUncancel
