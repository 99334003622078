
// Libraries
import * as React from 'react'
import moment from "moment"
import Classnames from 'classnames'

// Components
import Modal from "../../modal"
import AdminEventViewRow from "./adminEventViewRow"
import Button from "../../button"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarPlus, faCalendarTimes, faTrashAlt} from '@fortawesome/pro-solid-svg-icons'
import NumberFormat from "react-number-format"

class AdminEventView extends React.Component {
	render() {
		const {event, handleClose, handleOpenEdit, handleConfirmCancel, handleConfirmUncancel, handleConfirmDelete} = this.props

		let classNames = Classnames([
			'admin-view',
			'admin-view--event',
			{
				'admin-view--event-cancelled': event.status_id === 5,
			},
		])

		const tagNames = event.tags.map((tag) => {
			return tag.name.en
		})

		event.requested_tickets_count = event.pending_tickets_count + event.successful_tickets_count + event.rejected_tickets_count

		return <Modal handleClose={handleClose} modalRight>
			<div className={classNames}>
				<div className="admin-view__header">
					<div className="column">
						<h3>{event.name}</h3>
						<p>Published: {moment(event.publish_at).format('DD MMM YYYY')}</p>
					</div>
					<div className="column">
						<ul className="admin-view__header-nav">
							<li><Button onClick={() => handleOpenEdit(event.id)} colorEndeavour>
								Edit Event
							</Button></li>
							<li><Button to={`/admin/events/new?duplicate=${event.id}`} hollow colorEndeavour>
								Duplicate
							</Button></li>

							{event.status_id !== 5 &&  <li><Button onClick={() => handleConfirmCancel()} iconOnly danger>
								<FontAwesomeIcon icon={faCalendarTimes} title="Cancel icon" />
								<span className="sr-only">Cancel Event</span>
							</Button></li>}
							{event.status_id === 5 &&  <li><Button onClick={() => handleConfirmUncancel()} iconOnly>
								<FontAwesomeIcon icon={faCalendarPlus} title="Uncancel icon" />
								<span className="sr-only">Uncancel Event</span>
							</Button></li>}
							{event.status_id === 5 && <li><Button onClick={() => handleConfirmDelete()} iconOnly danger>
								<FontAwesomeIcon icon={faTrashAlt} title="Delete icon" />
								<span className="sr-only">Delete Event</span>
							</Button></li>}
						</ul>
					</div>
				</div>
				<div className="admin-view__info">
					<p><b>Status</b> {event.status ? event.status.name : '-'}</p>

					{event.status_id !== 5 && <>
						<p><b>Publish Date</b> {moment(event.publish_at).format('DD MMM YYYY')}</p>
						<p><b>Closing Date</b> {moment(event.application_deadline).format('DD MMM YYYY')}</p>

						<p><b>Ballot Count</b> {event.ballots_count}</p>
						<p><b>Tickets Requested</b> {event.tickets_requested_count}</p>

						<p><b>Winning Ballots</b> {event.winner_all_ballots_count}</p>
						<p><b>Tickets Available</b> {event.tickets_available}</p>
						<p><b>Tickets Won: Pending</b> {event.winner_pending_tickets_count}</p>
						<p><b>Tickets Won: Accepted</b> {event.winner_accepted_tickets_count}</p>
						<p><b>Tickets Won: Rejected</b> {event.winner_rejected_tickets_count}</p>
						<p><b>Tickets Won: Requested</b> {event.winner_requested_tickets_sum}</p>
						<p><b>Tickets Won: Fees</b> <NumberFormat value={event.winner_fees_sum} displayType={"text"} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'£'}/></p>

						<Button to={`/admin/events/ballots/${event.id}`}>See Ballots</Button>
					</>}

					{event.status_id === 5 && <>
						<p><b>Cancelled Date</b> {moment(event.cancelled_at).format('DD MMM YYYY')}</p>
						{event.cancelled_by &&
							<p><b>Cancelled By</b> {event.cancelled_by?.first_name} {event.cancelled_by?.last_name}</p>}
						<p><b>Cancelled Ballots</b> {event.cancelled_ballots_count}</p>
						<p><b>Cancelled Tickets Requested</b> {event.cancelled_tickets_count}</p>
					</>}
				</div>
				<div className="admin-view__view">
					<div className="admin-view__view-group">
						<AdminEventViewRow label="Event Name" value={event.name} />
						<AdminEventViewRow label="Artist" value={event.artist?.name} />
						<AdminEventViewRow label="Event Type" value={event.type.name} />
						<AdminEventViewRow label="Tags" value={tagNames.length
							? <ul>{tagNames.map((tag) => <li>{tag}</li>)}</ul>
							: 'None'} />
					</div>
					<div className="admin-view__view-group">
						<AdminEventViewRow label="City" value={event.venue?.addresses[0].city} />
						<AdminEventViewRow label="Venue" value={event.venue?.name} />
						<AdminEventViewRow label="Event Date" value={moment(event.starts).format('DD MMM YYYY')} />
						<AdminEventViewRow label="Door Times" value={moment(event.door_times).format('HH:mm')} />
						<AdminEventViewRow label="Event Start" value={moment(event.starts).format('HH:mm')} />
					</div>
					<div className="admin-view__view-group">
						<AdminEventViewRow label="Tickets Available" value={event.tickets_available} />
						<AdminEventViewRow label="Max Per Transaction" value={event.max_tickets_per_transaction >= 0 ? event.max_tickets_per_transaction : '-'} />
						<AdminEventViewRow label="Face Value/1 Ticket" value={`£ ${event.ticket_face_value}`} />
					</div>
					<div className="admin-view__view-group">
						<AdminEventViewRow label="Geo-locked?" value={event.geo_locked === 1 ? "Yes": "No"} />
						<AdminEventViewRow label="Geo-lock Radius" value={event.geo_locked ? `${event.geo_lock_radius} miles` : '-'} />
					</div>
					<div className="admin-view__view-group">
						<AdminEventViewRow label="Synopsis" value={event.synopsis} />
						<AdminEventViewRow label="Tour Logo" value={event.logo ? <img src={event.logo} className='event-tour-logo' alt="Tour logo" /> : '-'} />
						<AdminEventViewRow label="Feature Image" value={event.image ? <img src={event.image} className='event-feature-image' alt="Featured" /> : '-'} stacked />
					</div>
				</div>
			</div>
		</Modal>
	}
}

export default AdminEventView
