
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class EventTypeService {
	async get() {
		return await apiService.get('events/types')
	}
}
