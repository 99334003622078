
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import InputFactory from "../../inputFactory"
import Button from "../../button"

class AdminEventConfirmCancel extends React.Component {
	state = {
		cancelMessage: '',
		formValue: '',
		isConfirming: false
	}

	render() {
		const {handleClose} = this.props
		const {isConfirming} = this.state

		return <Modal handleClose={handleClose} modalConfirm>
			<div className="modal-confirm">
				<h2>Cancel this event?</h2>
				<p>All users that submitted their entries will be notified via email.</p>
				<InputFactory
					type="textarea"
					name="message"
					label="Optional reason for cancelling the event"
					placeholder="Message explaining cancellation"
					onChange={this.handleInputChange}
					rows="5"
					value={this.state.cancelMessage}
				/>
				<ul className="button-wrap">
					<li><Button onClick={handleClose} hollow colorEndeavour>Back</Button></li>
					<li><Button onClick={this.handleConfirm} isLoading={isConfirming} colorPomegranate>Cancel Event</Button></li>
				</ul>
			</div>
		</Modal>
	}

	handleInputChange = value => {
		this.setState({cancelMessage: value || ''})
	}

	handleConfirm = async () => {
		const {event, handleConfirm} = this.props

		this.setState({
			isConfirming: true
		})

		handleConfirm(event.id, this.state.cancelMessage).then(() => {
			this.setState({
				isConfirming: false
			})
		})
	}
}

export default AdminEventConfirmCancel
